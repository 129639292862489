@import "./bourbon/bourbon";

*{
  margin: 0;
  padding: 0;
}

html {
  font-size: 10px;

  @media screen and (min-width: 320px) {
  }
  @media screen and (min-width: 375px) {
    font-size: 8px;
  }
  @media screen and (min-width: 768px) {
    font-size: 10px;
  }
  @media screen and (min-width: 1280px) {
    font-size: 10px;
  }
  @media screen and (min-width: 1440px) {
    font-size: 12px;
  }
  @media screen and (min-width: 1920px) {
    font-size: 12px;
  }
}

body {
  margin: 0;
  padding: 0;
  font-size: 1.17rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "PingFangSC-Regular","PingFangSC-Light","Roboto","Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", "STHeiti Light", "STHeiti", "WenQuanYi Micro Hei",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  background: #fafafa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  @include transition( background-color 300ms ease-in-out, color 300ms ease-in-out );
}
a, a:link, a:visited, a:hover {
  text-decoration: none;
}
a:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

input[type=text], input[type=password], input[type=number], input[type=tel], input[type=date], input[type=datetime], input[type=month], input[type=time], input[type=button], select {
  outline: 0;
}
