.header-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 2.5rem;
  height: 7rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 100;

  background-color: rgba(255,255,255,0.94);
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.03);
  transition: box-shadow 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

  &.over-top {
    box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.4);
  }

  & > div:first-of-type {
    display: flex;
    align-items: center;
  }

  .info {
    margin: 0 0 0 2rem;
    padding: 0;

    h2.title {
      font-family: inherit;
      font-size: 2.5rem;
      color: #2c3d52;

      //position: relative;
      //display: block;
      //padding-bottom: 0.55em;
      //
      //>span, >small {
      //  display: block;
      //}

      >small {
        //position: absolute;
        //top: auto;
        //bottom: 0;
        //right: 0;
        //padding-right: 0;
        //padding-left: 0;
        //margin: 0;
        background-color: transparent;
        //background-color: #c1cbe1;
        border: 1px solid #ccc;
        margin-left: 0.7rem;

        pre {
          font-size: 1.24rem;
          color: #ccc;

          //&:before {
          //  content: "v";
          //}
        }
      }
    }
  }

  img.logo {
    height: 50px;
  }

  ol,ul,li{
    list-style: none;
  }
  ul {
    display: flex;
    align-items: center;
    height: 100%;

    li {
      padding: 0 1rem;
      margin: 0 1rem;
      height: 100%;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      cursor: pointer;

      span {
        font-size: 1.5rem;
        color: #708298;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);

        span {
          color: #5b697b;
        }
      }

      &.active {
        border-bottom: 2px solid #406eff;
        background-color: inherit;
        cursor: default;

        span {
          color: #406eff;
        }
      }
    }
  } //ul

  .auth-record {
    position: absolute;
    right: 0;
    top: 0.5rem;
    bottom: 0.5rem;
    padding: 0.8rem 1rem;
    border-radius: 8px 0 0 8px;
    background-color: rgba(204, 204, 204, 0.5);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    cursor: pointer;

    h3 {
      display: block;
      font-size: 1.28rem;
      color: #525252;

      span {
        display: none;
        margin-left: 0.5em;
        font-size: 1.16rem;
        font-weight: normal;
      }

      span.hints {
        margin-left: 1.8em;
        color: #684d40;
        font-weight: bold;
      }
    }

    .token {
      position: relative;
      border-top: 1px dotted #f2f2f2;
      padding-top: 0.3rem;
      margin-top: 0.3rem;
      font-size: 1rem;
      color: #717171;
      width: 0;
      right: 99999px;
      max-height: 0;
      line-height: 110%;
      word-break: break-all;
      overflow-y: auto;
      transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    }

    input {
      position: absolute;
      right: 99999px;
      height: 0;
      width: 0;
    }

    &:hover {
      h3 span {
        display: inline-block;
      }
      .token {
        width: 50em;
        max-height: 2.4em;
        right: auto;
      }
    }
  }

}//.header-bar
