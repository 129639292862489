.popup-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -10000px;
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 300;

  background-color: rgba(189, 189, 189, 0.92);
  transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 250ms;


  .panel {
    position: absolute;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 100ms;
    box-sizing: border-box;
    padding: 1.8rem 2.6rem;
    margin: 0 auto;
    width: 90%;
    top: 3rem;
    bottom: 3rem;
    font-size: 1.15rem;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.4);
    z-index: 10;
    opacity: 0;
    overflow-y: auto;
  }


  &.show {
    left: 0;

    .panel {
      opacity: 1;
    }
  }

  .markdown {
    font-size: 1.3rem;

    p {
      font-size: 1.2em;
      margin: 0.3em 0;
      color: #525252;
    }

    h1 {
      font-size: 2em;
      padding: 0;
      margin: 0.9rem 0 2.6rem;
      text-align: center;
    }
    h2 {
      font-size: 1.8em;
      padding: 0;
      margin: 2.2rem 0 1.3rem;
    }
    h3 {
      font-size: 1.5em;
      padding: 0;
      margin: 2rem 0 1.1rem;
    }
    h4 {
      font-size: 1.3em;
      margin: 1.8rem 0 1rem;
    }
    h5 {
      font-size: 1.08em;
      margin: 1.6rem 0 0.8rem;
      font-weight: normal;

      &:before {
        content: "\2739";
        margin-right: 0.2em;
      }
    }


    ul, ol {
      margin: 1rem 0 1.4rem;
      padding: 0 0 0 1.4em;
      color: #525252;
    }
    li {
      font-size: 1.15em;
      line-height: 180%;
    }

    pre {
      background-color: #f2f2f2;
      padding: 1.2rem;
      border-radius: 3px;
      overflow-x: auto;
    }

    a, a:link, a:visited {
      color: #0c53bb;
    }
    a:hover {
      color: #0920e0;
    }

    hr {
      margin: 3rem 0 3.4rem;
      border: 0;
      height: 1px;
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0));
    }

    table {
      border: 1px solid rgba(59, 65, 81, 0.2);
      border-radius: 3px;
      padding: 0;
      border-spacing: 0;

      thead tr {
        background-color: rgba(59, 65, 81, 0.08);
        border-bottom: 1px solid rgba(59, 65, 81, 0.2);
        th {
          border-bottom: none;
          padding: 12px 10px;
        }
      }
      tbody tr {
        border-bottom: 1px solid rgba(59, 65, 81, 0.12);
        td {
          padding: 10px 10px;
        }
      }
      tbody tr:nth-child(even) {
        background-color: rgba(59, 65, 81, 0.045);
      }
    }
  }

}//.popup-container
