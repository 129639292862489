.tupu-operations {

  //tag section with operations under the tag
  .opblock-tag-section {
    margin-bottom: 0.5rem;
  }

  //tag name row
  .opblock-tag {
    font-size: 2.4rem;
    padding: 0.9rem 1.8rem 0.7rem 0.9rem;
    position: relative;
    //box-shadow: 0px 3px 8px -4px rgba(0,0,0,.1);

    //&:before {
    //  position: absolute;
    //  width: 2.5rem;
    //  top: 0;
    //  bottom: -1px;
    //  left: -2.5rem;
    //  background: radial-gradient(ellipse at bottom right,rgba(59,65,81,.15) 0%,transparent 60%);
    //  content: " ";
    //}
    //&:after {
    //  position: absolute;
    //  width: 2.5rem;
    //  top: 0;
    //  bottom: -1px;
    //  right: -2.5rem;
    //  background: radial-gradient(ellipse at bottom left,rgba(59,65,81,.15) 0%,transparent 60%);
    //  content: " ";
    //}
    &:before {
      position: absolute;
      width: 0.5rem;
      top: 0;
      bottom: -1px;
      left: -2.5rem;
      background-color: rgba(59,65,81,.2);
      content: " ";
    }
    &:after {
      position: absolute;
      width: 0.5rem;
      top: 0;
      bottom: -1px;
      right: -2.5rem;
      background-color: rgba(59,65,81,.2);
      content: " ";
    }

    small {
      font-size: 0.8em;

      .markdown {
        p {
          margin: 0.3em 0;
        }
      }
    }

  }//.opblock-tag

  .opblock {
    .opblock-summary {
      padding: 0;
    }

    //operation method
    .opblock-summary-method {
      padding: 0.7rem 1.6rem;
      min-width: 6rem;
      border-radius: 3px 0 0 3px;
    }
    &.is-open .opblock-summary-method {
      border-radius: 3px 0 0 0;
    }

    //operation path
    .opblock-summary-path {
      //flex-grow: 1;
      flex: 1 1 auto;
      font-size: 1.5rem;
    }

    .opblock-description-wrapper {
      padding: 10px 20px 7px;

      .markdown {
        p {
          font-size: 1.1em;
        }
        h2 {
          font-size: 1.23em;
          margin: 1.1em 0 0.5em;
        }
        h3 {
          font-size: 1.18em;
          margin: 0.9em 0 0.4em;
        }
        h4 {
          font-size: 1.08em;
          margin: 0.7em 0 0.3em;
        }
        h5 {
          font-size: 1.02em;
          margin: 0.8em 0 0.3em;
          font-weight: normal;

          &:before {
            content: "\2739";
            margin-right: 0.2em;
          }
        }

        ul, ol {
          padding: 0 0 0 1.4em;
          margin: 0.4em 0 0.6em;
        }
      }
    }

    //operatipon desc
    .opblock-summary-description {
      flex: none;
      margin-right: 1em;
    }

    //section header in operation block, such as Parameters/Response
    .opblock-section-header {
      //min-height: auto;
      //padding: 1em 20px;
      background-color: hsla(0,0%,100%,.4);
      border-top: 1px solid rgba(0,0,0,.1);
      box-shadow: 0 1px 10px rgba(0,0,0,.06);
    }

    .table-container {
      padding: 0.8rem 20px;

      th.col {
        font-size: 1.2rem;
        padding: 0.5rem 0;
      }
    }

    .parameter__name.required:after {
      content: " ";
    }

    .try-out, .body-param-edit {
      display: none;
    }

    .opblock-body {

      table {
        .markdown {
          p {
            margin: 0 auto 0.5em;
          }
          li {
            line-height: 120%;
          }
        }

        tr {
          td:first-of-type {
            width: 20rem;
          }

          td {
            padding-top: 0.4rem;
          }
          &:first-of-type td {
            padding-top: 0.7rem;
          }

          &.tupu-layout-param {
            border-bottom: 1px dashed rgba(255,255,255,.6);

            $input-text-padding: 6px 8px;
            $input-text-margin: 2px 0;

            .col {
              padding-bottom: 0.5rem;
            }

            td.parameters-col_name {
              .parameter__name {
                //line-height: 1.15;
                padding: $input-text-padding;
                margin: $input-text-margin;
              }
              .parameter__type, .parameter__in {
                display: none;
              }
            }

            td.parameters-col_description {
              padding-right: 3rem;
              display: flex;
              flex-direction: column;

              >.markdown {
                display: none;
                order: 1;
              }

              >input[type=text] {

                order: 2;
              }

              >.tupu-param-info {
                order: 3;
              }
            }

            .tupu-param-info {
              .parameter__type, .parameter__in {
                display: inline-block;
                font-size: 1.4rem;
                padding: 0 0 0.2em;
              }

              .parameter__in {
                font-style: normal;
                min-width: 10em;

                &:before {
                  content: '@';
                }
              }
            }

            ul.tab {
              margin: 6px 0 1rem;

              li.tabitem {
                min-width: 4em;
              }
            }

            textarea {
              border: 1px solid #d9d9d9;
              margin: 0;
            }
            select {
              margin: 5px 0;
              //width: 340px;
              max-width: 200px;
              min-width: 60px;
            }
            input[type=text] {
              max-width: none;
              //width: 340px;
              width: 75%;
              padding: $input-text-padding;
              margin: $input-text-margin;
              font-size: 1.4rem;
            }

            .tupu-description {
              width: 38%;

              .markdown {
                padding-top: 0.4rem;
              }

              .example_caption {
                margin: 0 0 1rem;
                font-size: 12px;
              }

              .highlight-code {

                >pre {
                  //background-color: rgba(0,0,0,.1);
                  //border: 1px solid #cecece;
                  background-color: transparent;

                  span {
                    color: #555 !important;
                  }
                }
              }
            }

          }//&.tupu-layout-param
        }
      } //table

      .model-box {
        width: 100%;

        .model-title {
          font-size: 1.16em;
          text-decoration: underline;
          color: #222e57;
          padding-bottom: 0.4em;
          display: inline-block;
        }
      }

      .execute-wrapper {
        button:hover {
          border-bottom: 1px solid rgba(0,0,0,0.12);
          box-shadow: 0 2px 4px 0 rgba(0,0,0,.45);
        }
      }
      .execute-wrapper, .btn-group {
        justify-content: flex-end;
        padding: 0.6rem 20px 1.3rem;

        button {
          min-width: 22rem;
          max-width: 25rem;
          padding-top: 0;
          padding-bottom: 0;
          height: 36px;
          box-sizing: border-box;
          //transition: all .2s;

          //&:hover {
          //  border-bottom: 1px solid rgba(0,0,0,0.12);
          //  box-shadow: 0 2px 4px 0 rgba(0,0,0,.45);
          //}
        }
      }
      .btn-group {
        button {
          &.btn-clear {
            //border: none;
            //color: white;
            //background-color: #999;
          }

          //&:not(:first-of-type) {
          //  margin-left: 2.8rem;
          //}
          &:first-of-type {
            border-radius: 4px 0 0 4px;
          }
          &:last-of-type {
            border-radius: 0 4px 4px 0;
          }
        }
      }
      textarea.curl {
        min-height: 2em;
      }

      .responses-wrapper {
        ul.tab {
          margin: 0.5rem 0 1rem;

          li.tabitem {
            min-width: 4em;
          }
        }

        .model-box {
          display: block;
        }
      }

      .response-col_description__inner {
        >.markdown {
          background-color: rgba(56, 63, 78, 0.88);
        }
      }
      .highlight-code {
        pre.example.microlight {
          max-height: 250px;
          background-color: rgba(56, 63, 78, 0.88);
        }

        @import  "./github.scss";
      }


      .responses-inner {
        >div:first-of-type > h4 {
          padding-top: 1em;
        }
      }

    }//.opblock-body


  }//.opblock


}//.tupu-operations