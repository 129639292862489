.doc-container {
  margin: 10rem 2.5rem 5rem;

  .info.description {
    margin: 0 0 3rem;
    padding: 1rem;
    background-color: #f4f4f4;

    .markdown {
      h1 {
        font-size: 1.8em;
        padding: 0;
        margin: 1.3rem 0;
      }
      h2 {
        font-size: 1.6em;
        padding: 0;
        margin: 1.1rem 0;
      }
      h3 {
        font-size: 1.4em;
        padding: 0;
        margin: 0.9rem 0;
      }

      ul {
        margin: 0.4rem 0 0.9rem;
      }

      li {
        font-size: 1.2em;
      }
    }
  }//.info.description

  .markdown {

    ul {
      padding: 0 0 0 1.4em;
      margin: 0.4em 0 0.6em;
    }
  }

}//.doc-container